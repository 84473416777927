import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';

import '../../generic-page.scss';
import './guides.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Card } from 'react-bootstrap';
import { ZZZCharacter } from '../../../modules/zzz/common/components/zzz-character';

const ZZZFreeCharacters: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page zzz guide-zzz'} game="zzz">
      <ul className="breadcrumb">
        <li>
          <Link to="/zenless/">Zenless Zone Zero</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/zenless/guides/">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Free characters</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/zzz/categories/category_free.jpg"
            alt="Characters"
          />
        </div>
        <div className="page-details">
          <h1>Zenless Zone Zero (ZZZ) Free characters</h1>
          <h2>
            List of characters you can obtain for free in Zenless Zone Zero
            (ZZZ) and how strong they are!
          </h2>
          <p>
            Last updated: <strong>03/07/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          <p>
            As you progress through the story in Zenless Zone Zero you will
            obtain quite a lot of free{' '}
            <StaticImage
              src="../../../images/zzz/icons/rarity_a.png"
              alt="A Rank"
              className="faction-icon"
            />{' '}
            <strong>A-Rank</strong> characters. This guide will list all the
            free characters and also let you know when you will be able to
            obtain them - so you can easier plan your early game progression
            team!
          </p>
          <p>
            Also keep in mind that a lot of the{' '}
            <StaticImage
              src="../../../images/zzz/icons/rarity_a.png"
              alt="A Rank"
              className="faction-icon"
            />{' '}
            <strong>A-Rank</strong> characters are actually really strong and
            can be the backbone of your team for quite a long time.
          </p>
        </p>
        <SectionHeader title="Free characters" />
        <p>
          During the tutorial you will obtain 3 agents for free -{' '}
          <strong>Anby, Nicole and Billy</strong>:
        </p>
        <div className="zzz-container">
          <Card className="avatar-card">
            <ZZZCharacter
              slug="anby-demara"
              mode="card"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <ZZZCharacter
              slug="nicole-demara"
              mode="card"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <ZZZCharacter
              slug="billy-kid"
              mode="card"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>
          And those Agents you can obtain for free from progressing through the
          game and from various events:
        </p>
        <div className="zzz-container">
          <Card className="avatar-card">
            <ZZZCharacter
              slug="ben"
              mode="card"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <ZZZCharacter
              slug="corin"
              mode="card"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <ZZZCharacter
              slug="soukaku"
              mode="card"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>Sources:</p>
        <ul>
          <li>
            <strong>Ben</strong> - complete Chapter 2 Story 1,
          </li>
          <li>
            <strong>Corin</strong> - pre-register reward,
          </li>
          <li>
            <strong>Soukaku</strong> - complete Shiyu Defense 3-4,
          </li>
        </ul>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default ZZZFreeCharacters;

export const Head: React.FC = () => (
  <Seo
    title="Free characters | Zenless Zone Zero | Prydwen Institute"
    description="List of characters you can obtain for free in Zenless Zone Zero (ZZZ) and how strong they are!"
    game="zzz"
  />
);
